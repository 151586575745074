import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "@glideapps/glide-data-grid/dist/index.css";
import axios from "../../utils/authAxios";
import {useUndoRedo} from "../../utils/undoRedo";
import {RiFileExcel2Line} from "react-icons/ri";
import {MdDownload} from "react-icons/md";
import {downloadXlsx} from "../../utils/fileDownload";
import {DataEditor} from "@glideapps/glide-data-grid";
import {useLayer, useMousePositionAsTrigger} from "react-laag";
import styles from './Spreadsheet.module.css'
import {Button, Checkbox, Input, List} from "antd";
import {GrSearch} from "react-icons/gr";
import {BsTrash3} from "react-icons/bs";

const Spreadsheet = ({extractedData}) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [showSearch, setShowSearch] = useState(false)

    const containerRef = useRef(null);
    const gridRef = useRef(null);

    useEffect(() => {
        const preferredColumnOrder = JSON.parse(localStorage.getItem('columns')) ?? []
        const newColumn = extractedData.map(v => v.columns)
        const parseColumns = (columns) => (columns.map((c, i) => c.map(v => ({
            ...v,
            id: `${i}-${v.key}`,
            hasMenu: true,
            menuIcon: 'dots'
        }))))
        const sortColumn = (column) => ([...column].sort((a, b) => {
            if (a.key < b.key) return -1
            if (a.key > b.key) return 1
            return 0
        }))
        if (JSON.stringify(preferredColumnOrder.map(v => sortColumn(v))) === JSON.stringify(newColumn.map(v => sortColumn(v)))) setColumns(parseColumns(preferredColumnOrder))
        else setColumns(parseColumns(newColumn))
    }, [extractedData]);

    const getData = useCallback(([col, row]) => {
        const cellData = `${rows[row][columns[currentTab][col].key] || ''}`
        return {
            kind: 'text',
            allowOverlay: true,
            data: cellData,
            displayData: cellData,
        }
    }, [currentTab, columns, rows])
    const onColumnResize = useCallback((column, newSize) => {
        setColumns(prevState => {
            const index = prevState[currentTab].findIndex(ci => ci.title === column.title)
            const currentColumn = [...prevState[currentTab]]
            currentColumn[index].width = newSize
            prevState.splice(currentTab, 1, currentColumn)
            return [...prevState]
        })
    }, [currentTab]);
    const onColumnMoved = useCallback((startIndex, endIndex) => {
        setColumns(prevState => {
            const newCols = [...prevState[currentTab]];
            const [toMove] = newCols.splice(startIndex, 1);
            newCols.splice(endIndex, 0, toMove);
            prevState.splice(currentTab, 1, newCols)
            localStorage.setItem('columns', JSON.stringify(prevState.map(c => c.map(v => ({
                key: v.key,
                title: v.title
            })))))
            return [...prevState];
        });
    }, [currentTab]);

    const [events, setEvents] = useState([])
    const onCellsEditedHandler = useCallback((newValues) => {
        newValues.forEach(newValue => {
            const [col, row] = newValue.location
            const value = newValue.value
            const currentColumn = columns[currentTab][col]
            setRows(prevState => {
                const currentRow = [...prevState]
                currentRow[row] = {...currentRow[row], [currentColumn.key]: value.data}
                return currentRow
            })
            setEvents(prevState => [...prevState, {col, row, key: currentColumn.key, value: value.data}])
        })
    }, [currentTab, columns]);
    const eventDataDefault = (obj = {}) => {
        return new Proxy(obj, {
            get: (target, key) => {
                if (!(key in target)) target[key] = {extraction_id: null, event_type: null, event_data: {}};
                return target[key];
            }
        });
    }
    useEffect(() => {
        if (events.length === 0) return
        const eventData = eventDataDefault()
        events.forEach(event => {
            const extractionId = rows[event.row]['_extraction_id']
            if (extractionId) {
                eventData[extractionId].extraction_id = extractionId
                eventData[extractionId].event_type = 'update'
                eventData[extractionId].event_data[event.key] = event.value
            } else {
                const historyId = rows[event.row]['_history_id']
                eventData[historyId].event_type = 'history_update'
                eventData[historyId].event_data['_history_id'] = historyId
                eventData[historyId].event_data[event.key] = event.value
            }
        })
        axios.put('/v1/events', Object.values(eventData)).then()
        setEvents([])
    }, [events, rows]);

    const [deleteRows, setDeleteRows] = useState(null)

    const {
        gridSelection,
        onCellsEdited,
        onGridSelectionChange,
        changeRows,
        setChangeRows
    } = useUndoRedo(gridRef, getData, currentTab, onCellsEditedHandler, deleteRows, setDeleteRows);

    const [showMenu, setShowMenu] = useState(undefined)
    const filteringCheckboxOptionsRef = useRef({})
    const [filteringCheckboxTempOptions, setFilteringCheckboxTempOptions] = useState([])
    const [filteringCheckboxTempValues, setFilteringCheckboxTempValues] = useState([])
    const [filteringCheckboxValues, setFilteringCheckboxValues] = useState({})
    const checkAll = !new Set(filteringCheckboxTempOptions).difference(new Set(filteringCheckboxTempValues)).size
    const {renderLayer: menuLayer, layerProps: menuLayerProps} = useLayer({
        isOpen: showMenu !== undefined,
        triggerOffset: 4,
        onOutsideClick: () => setShowMenu(undefined),
        trigger: {
            getBounds: () => ({
                left: showMenu?.bounds.x ?? 0,
                top: showMenu?.bounds.y ?? 0,
                right: (showMenu?.bounds.x ?? 0) + (showMenu?.bounds.width ?? 0),
                bottom: (showMenu?.bounds.y ?? 0) + (showMenu?.bounds.height ?? 0),
                width: showMenu?.bounds.width ?? 0,
                height: showMenu?.bounds.height ?? 0,
            }),
            getParent: () => containerRef.current
        },
        overflowContainer: false,
        placement: "bottom-end",
        auto: true,
        possiblePlacements: ["bottom-start", "bottom-end"],
    });
    const filteringRowOptionsDefault = (obj = {}) => {
        return new Proxy(obj, {
            get: (target, key) => {
                if (!(key in target)) target[key] = []
                return target[key];
            }
        });
    }
    const onHeaderMenuClick = useCallback((col, bounds) => {
        setShowMenu({col, bounds})
        const key = columns[currentTab][col].key
        const filteringRowOptions = filteringRowOptionsDefault()
        extractedData[currentTab].rows.forEach((value, index) => {
            const v = value[key]
            filteringRowOptions[v].push(index)
        })
        filteringCheckboxOptionsRef.current.new = filteringRowOptions
        const tempOptions = Object.keys(filteringRowOptions)
        const tempValues = filteringCheckboxValues.sheet === currentTab && filteringCheckboxValues.column === key ? filteringCheckboxValues.values : tempOptions
        setFilteringCheckboxTempOptions([...tempValues.sort(), ...Array.from(new Set(tempOptions).difference(new Set(tempValues))).sort()])
        setFilteringCheckboxTempValues(tempValues)
    }, [extractedData, currentTab, columns, filteringCheckboxValues])
    const onFilteringInputChange = useCallback(e => setFilteringCheckboxTempOptions(Object.keys(filteringCheckboxOptionsRef.current.new).filter(v => v.toLowerCase().includes(e.target.value.toLowerCase())).sort()), [filteringCheckboxOptionsRef])
    const onFilteringCheckboxChange = useCallback(list => setFilteringCheckboxTempValues(prevState => Array.from(new Set(prevState).difference(new Set(filteringCheckboxTempOptions)).union(new Set(list)))), [filteringCheckboxTempOptions])
    const onFilteringCheckboxAllChange = useCallback(e => setFilteringCheckboxTempValues(e.target.checked ? filteringCheckboxTempOptions : []), [filteringCheckboxTempOptions])
    const onFilterApply = useCallback(() => {
        const filter = Array.from(new Set(filteringCheckboxTempOptions).intersection(new Set(filteringCheckboxTempValues)))
        filteringCheckboxOptionsRef.current.applied = filteringCheckboxOptionsRef.current.new
        setFilteringCheckboxValues(Object.keys(filteringCheckboxOptionsRef.current.new).length === filter.length ? {} : {
            sheet: currentTab,
            column: columns[currentTab][showMenu.col].key,
            values: filter
        })
        setShowMenu(undefined)
    }, [currentTab, columns, showMenu, filteringCheckboxOptionsRef, filteringCheckboxTempOptions, filteringCheckboxTempValues])
    const onSort = useCallback(key => {
        setRows(prevState => {
            const sortKey = columns[currentTab][showMenu.col].key
            return [...prevState.sort((a, b) => {
                if (a[sortKey] < b[sortKey]) return key === 'asc' ? -1 : 1
                if (a[sortKey] > b[sortKey]) return key === 'asc' ? 1 : -1
                return 0
            })]
        })
        setShowMenu(undefined)
    }, [columns, currentTab, showMenu])

    const {
        hasMousePosition: showContextMenu,
        resetMousePosition: closeContextMenu,
        handleMouseEvent,
        trigger
    } = useMousePositionAsTrigger();
    const {renderLayer: contextMenuLayer, layerProps: contextMenuLayerProps} = useLayer({
        isOpen: showContextMenu,
        onOutsideClick: closeContextMenu,
        trigger,
        placement: 'right-start',
        auto: true,
    });
    const selectedRows = useMemo(() => {
        if (gridSelection?.current) {
            const range = gridSelection.current.range
            return [{start: range.y, end: range.y + range.height - 1}]
        } else if (gridSelection?.rows) {
            return gridSelection.rows.items.map(v => ({start: v[0], end: v[1] - 1}))
        } else return []
    }, [gridSelection])
    const onRowsDelete = useCallback(() => {
        closeContextMenu()
        setDeleteRows(selectedRows.reverse().map(v => ({start: v.start, rows: rows.slice(v.start, v.end + 1)})))
    }, [closeContextMenu, selectedRows, rows])
    useEffect(() => {
        if (changeRows) {
            const isDelete = changeRows.operation === 'delete'
            setRows(prevState => {
                const newRows = [...prevState]
                changeRows.rows.forEach(v => {
                    if (isDelete) newRows.splice(v.start, v.rows.length)
                    else newRows.splice(v.start, 0, ...v.rows)
                })
                return newRows
            })
            axios.put('/v1/events', changeRows.rows.flatMap(v => v.rows).map(v => ({
                extraction_id: v._extraction_id,
                event_type: isDelete ? 'delete' : 'update'
            }))).then()
            setChangeRows(null)
        }
    }, [changeRows, setChangeRows]);

    useEffect(() => {
        const onKeyDown = (event) => {
            if (event.code === "KeyF" && (event.ctrlKey || event.metaKey)) {
                event.preventDefault();
                event.stopPropagation();
                setShowSearch(v => !v);
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown)
        };
    }, []);

    useEffect(() => {
        if (filteringCheckboxValues.values && filteringCheckboxValues.sheet === currentTab) {
            const filteredRows = []
            filteringCheckboxValues.values.forEach(v => filteringCheckboxOptionsRef.current.applied?.[v].forEach(i => filteredRows.push(extractedData[currentTab].rows[i])))
            setRows(filteredRows)
        } else setRows(extractedData[currentTab]?.rows ?? [])
    }, [extractedData, currentTab, filteringCheckboxOptionsRef, filteringCheckboxValues]);

    return (
        <div ref={containerRef} className={'space-y-3 h-full flex flex-col'}>
            <div className="flex space-x-3">
                {extractedData.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentTab(index)}
                        className={`${
                            currentTab === index ? "font-medium bg-gray-200 text-blue-500" : "bg-gray-100 text-gray-600"
                        } px-4 h-9 rounded-lg text-sm flex-none`}>
                        {item['sheet_name']}
                    </button>
                ))}
                <div className={'flex-grow'}/>
                <div className={'flex flex-none'}>
                    <button onClick={() => downloadXlsx(extractedData)}
                            className="px-2 py-1 bg-gray-100 rounded-lg text-xs text-green-700 flex space-x-2 items-center transition hover:bg-gray-200">
                        <RiFileExcel2Line className="w-4 h-4"/>
                        <p>Export to Excel</p>
                        <MdDownload className="w-4 h-4"/>
                    </button>
                </div>
            </div>
            <div className="flex-1 overflow-auto text-gray-700 space-y-8 pb-2">
                <div className="h-full text-xs font-WantedSans" style={{minHeight: "300px"}}
                     onContextMenu={handleMouseEvent}>
                    <DataEditor ref={gridRef}
                                columns={columns[currentTab] ?? []}
                                getCellContent={getData}
                                rows={rows.length}
                                rowMarkers={'clickable-number'}
                                rowSelect={'multi'}
                                rowHeight={24}
                                headerHeight={26}
                                getCellsForSelection={true}
                                width={'100%'}
                                height={'100%'}
                                onPaste={true}
                                showSearch={showSearch}
                                onSearchClose={() => setShowSearch(false)}
                                gridSelection={gridSelection ?? undefined}
                                onGridSelectionChange={onGridSelectionChange}
                                onHeaderMenuClick={onHeaderMenuClick}
                                onColumnResize={onColumnResize}
                                onColumnMoved={onColumnMoved}
                                onCellsEdited={onCellsEdited}
                                onCellContextMenu={() => setShowMenu(undefined)}/>
                    <div id="portal" style={{position: 'fixed', left: 0, top: 0, zIndex: 9999}}/>
                    {showMenu && menuLayer(
                        <div {...menuLayerProps} className={styles.menu}>
                            <List className={'mb-2'}
                                  dataSource={[{'key': 'asc', 'text': 'Sort ▲'}, {'key': 'desc', 'text': 'Sort ▼'}]}
                                  renderItem={(item) => <List.Item className={styles.item}
                                                                   onClick={() => onSort(item.key)}>{item.text}</List.Item>}/>
                            <div className={'px-2 mb-2 flex flex-col'}>
                                <Input onChange={onFilteringInputChange} suffix={<GrSearch/>}/>
                                <Checkbox className={'ml-auto'} checked={checkAll}
                                          onChange={onFilteringCheckboxAllChange}>Select all</Checkbox>
                                <Checkbox.Group style={{height: '10rem'}}
                                                className={`${styles.checkboxGroup} py-1 overflow-y-auto flex flex-col flex-nowrap border`}
                                                value={filteringCheckboxTempValues}
                                                options={filteringCheckboxTempOptions.map(value => ({
                                                    label: value || '(Blank)',
                                                    value: value
                                                }))}
                                                onChange={onFilteringCheckboxChange}/>
                            </div>
                            <div className={'flex flex-row justify-end space-x-3 px-2'}>
                                <Button type={'primary'} onClick={onFilterApply}>OK</Button>
                                <Button onClick={() => setShowMenu(undefined)}>Close</Button>
                            </div>
                        </div>
                    )}
                    {showContextMenu && contextMenuLayer(
                        <div {...contextMenuLayerProps} className={styles.menu}>
                            <List>
                                {selectedRows.length > 0 &&
                                    <List.Item className={`${styles.item}`} onClick={onRowsDelete}>
                                        <BsTrash3 className={'mr-1'}/>
                                        <span>Delete {selectedRows.length > 1 ? 'selected rows' : `row${selectedRows[0].start !== selectedRows[0].end ? `s ${selectedRows[0].start + 1} - ${selectedRows[0].end + 1}` : ''}`}</span>
                                    </List.Item>}
                            </List>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Spreadsheet;
