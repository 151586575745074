import {useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react";

const initialState = {
    undoHistory: [],
    redoHistory: [],
    canUndo: false,
    canRedo: false,
    isApplyingUndo: false,
    isApplyingRedo: false
};

function reducer(state, action) {
    const newState = {...state};

    switch (action.type) {
        case "undo":
            if (state.canUndo) {
                newState.undoHistory = [...state.undoHistory];
                newState.operation = newState.undoHistory.pop();
                newState.canUndo = newState.undoHistory.length > 0;
                newState.isApplyingUndo = true

                return newState;
            }
            return state;

        case "redo":
            if (state.canRedo) {
                newState.redoHistory = [...state.redoHistory];
                newState.operation = newState.redoHistory.pop();
                newState.canRedo = newState.redoHistory.length > 0;
                newState.isApplyingRedo = true

                return newState;
            }
            return state;

        case "operationApplied":
            newState.operation = undefined;
            newState.isApplyingRedo = false
            newState.isApplyingUndo = false

            return newState;

        case "edit":
            if (!state.isApplyingRedo && !state.isApplyingUndo) {
                // general case
                newState.undoHistory = [...state.undoHistory, action.batch];
                newState.redoHistory = [];
                newState.canUndo = true;
                newState.canRedo = false;
            }

            if (state.isApplyingUndo) {
                newState.redoHistory = [...state.redoHistory, action.batch];
                newState.canRedo = true;
            }

            if (state.isApplyingRedo) {
                newState.undoHistory = [...state.undoHistory, action.batch];
                newState.canUndo = true;
            }

            return newState;

        case 'clear':
            newState.undoHistory = []
            newState.redoHistory = []
            newState.canUndo = false
            newState.canRedo = false
            newState.isApplyingUndo = false
            newState.isApplyingRedo = false

            return newState

        default:
            throw new Error("Invalid action");
    }
}

export function useUndoRedo(gridRef, getCellContent, currentTab, onCellsEdited, deleteRows, setDeleteRows) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const isApplyingUndoRef = useRef(false);
    const isApplyingRedoRef = useRef(false);

    useEffect(() => {
        isApplyingUndoRef.current = state.isApplyingUndo;
        isApplyingRedoRef.current = state.isApplyingRedo;
    }, [state.isApplyingUndo, state.isApplyingRedo]);

    const [gridSelection, setGridSelection] = useState(null)
    const gridSelectionRef = useRef(null)
    const onGridSelectionChangedEdited = useCallback((newVal) => {
        setGridSelection(newVal);
        gridSelectionRef.current = newVal;
    }, [])

    const wrappedOnCellsEdited = useCallback(
        (newValues) => {
            const isApplyingUpdate = isApplyingUndoRef.current || isApplyingRedoRef.current
            if (!isApplyingUpdate && gridSelectionRef.current) {
                dispatch({
                    type: "edit",
                    batch: {
                        type: 'cell',
                        edits: newValues.map(value => ({
                            location: value.location,
                            value: getCellContent(value.location),
                        })),
                        selection: gridSelectionRef.current
                    },
                });
            }
            onCellsEdited(newValues);
        },
        [onCellsEdited, getCellContent]
    );

    const [changeRows, setChangeRows] = useState(null)

    useEffect(() => {
        if (deleteRows) {
            setChangeRows({operation: 'delete', rows: deleteRows})
            dispatch({
                type: "edit",
                batch: {
                    type: 'row',
                    operation: 'delete',
                    rows: deleteRows,
                    selection: gridSelectionRef.current
                },
            })
            setDeleteRows(null)
        }
    }, [deleteRows, setDeleteRows])

    useEffect(() => {
        dispatch({type: "clear"});
        setGridSelection(null)
        gridSelectionRef.current = null
    }, [currentTab]);


    const undo = useCallback(() => {
        dispatch({type: "undo"});
    }, [dispatch]);

    const redo = useCallback(() => {
        dispatch({type: "redo"});
    }, [dispatch]);

    // Apply a batch of edits to the grid
    useEffect(() => {
        if (state.operation && gridRef.current) {
            const prevState = {}

            if (state.operation.type === 'cell') {
                prevState.type = 'cell'
                prevState.edits = state.operation.edits.map(value => ({
                    location: value.location,
                    value: getCellContent(value.location)
                }))
                prevState.selection = gridSelectionRef.current
                onCellsEdited(state.operation.edits)
                setGridSelection(state.operation.selection)
                gridSelectionRef.current = state.operation.selection
                gridRef.current.updateCells(state.operation.edits.map(value => ({cell: value.location})));
            } else if (state.operation.type === 'row') {
                prevState.type = 'row'
                prevState.operation = state.operation.operation === 'delete' ? 'add' : 'delete'
                prevState.rows = state.operation.rows
                prevState.selection = gridSelectionRef.current
                setChangeRows({operation: prevState.operation, rows: prevState.rows})
                setGridSelection(state.operation.selection)
                gridSelectionRef.current = state.operation.selection
            }

            dispatch({
                type: "edit",
                batch: prevState,
            });

            dispatch({
                type: "operationApplied",
            });
        }
    }, [state.operation, gridRef, onCellsEdited, getCellContent, setGridSelection]);

    // Attach the keyboard shortcuts. CMD+Z and CMD+SHIFT+Z on mac, CTRL+Z and CTRL+Y on windows.
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.code === "KeyZ" && (e.metaKey || e.ctrlKey)) {
                if (e.shiftKey) {
                    redo();
                } else {
                    undo();
                }
            }

            if (e.code === "KeyY" && (e.metaKey || e.ctrlKey)) {
                redo();
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [undo, redo]);

    return useMemo(() => {
        return {
            undo,
            redo,
            canUndo: state.canUndo,
            canRedo: state.canRedo,
            onCellsEdited: wrappedOnCellsEdited,
            onGridSelectionChange: onGridSelectionChangedEdited,
            gridSelection,
            changeRows: changeRows,
            setChangeRows: setChangeRows,
        };
    }, [undo, redo, wrappedOnCellsEdited, state.canUndo, state.canRedo, onGridSelectionChangedEdited, gridSelection, changeRows]);
}
