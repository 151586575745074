import React from "react";
import BookDemoButton from "../buttons/BookDemoButton";

const SolutionIntro = () => {
  const firstStep = [
    "Upload the spreadsheet template you're currently using (e.g., sales data, customer info, project trackers).",
    "Make sure the spreadsheet has clear headers for smooth data mapping from emails.",
    "Tailor the spreadsheet format to match your business needs for optimal results.",
  ];

  const secondStep = [
    "Forward the emails containing the data you want to extract.",
    "Mailtocell extracts information from email content and automatically fills it into the right cells in your spreadsheet.",
    "No manual data entry required, saving time and reducing errors.",
  ];

  const thirdStep = [
    "That’s it! Your data is instantly organized and ready to use.",
    "When you're done, you can download or share the spreadsheet with your team.",
  ];

  const ExplanationCard = ({ number, title, body }) => (
    <article className="w-full">
      <header className="text-xl md:text-2xl font-medium flex items-center space-x-2 border-b pb-4">
        <div className="h-10 w-10 rounded-full bg-gray-700 text-white flex items-center justify-center">{number}</div>
        <h2>{title}</h2>
      </header>
      {body && (
        <section className="mt-4 space-y-4 text-base">
          {body.map((item, index) => (
            <p className="text-gray-500" key={index}>
              • {item}
            </p>
          ))}
        </section>
      )}
    </article>
  );

  const Header = ({ subtitle, title }) => (
    <header className="flex flex-col items-center">
      <h1 className="text-4xl font-semibold text-center leading-normal">{title}</h1>
      <p className="mt-4 mb-8 text-base max-w-lg text-gray-500 leading-relaxed text-center">{subtitle}</p>
      <div className="mb-12">
        <BookDemoButton />
      </div>
    </header>
  );

  return (
    <section className="flex w-full items-center justify-center flex-col py-16 text-gray-700 bg-gradient-to-b from-white to-neutral-50 px-4">
      <Header
        title="Automated Email-to-Spreadsheets Tool"
        subtitle="Convert emails into organized spreadsheet data with Mailtocell—automatically and effortlessly."
      />
      <div className="max-w-screen-md w-full relative mb-36 flex items-center justify-center group">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 px-2">
          <article className="w-full h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center">
            <h3 className="text-xl font-semibold">Ready to Use Immediately</h3>
            <p className="text-gray-400 mt-2">
              Mailtocell is designed for instant use—just forward your emails and watch the system handle the rest
              automatically.
            </p>
          </article>

          <article className="w-full h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center">
            <h3 className="text-xl font-semibold">Email-to-Spreadsheets</h3>
            <p className="text-gray-400 mt-2">
              Automatically populate your spreadsheet with data from your emails, reducing manual entry.
            </p>
          </article>
          <article className="w-full h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center">
            <h3 className="text-xl font-semibold">Works with Any Email Type</h3>
            <p className="text-gray-400 mt-2">
              Supports any email type—sales leads, customer inquiries, reports, and more.
            </p>
          </article>
          <article className="w-full h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center">
            <h3 className="text-xl font-semibold">Accurate and Fast</h3>
            <p className="text-gray-400 mt-2">
              Mailtocell ensures precise data extraction and spreadsheet population, faster than manual methods.
            </p>
          </article>
        </div>
      </div>

      <Header
        title="How Mailtocell Works"
        subtitle="Upload your spreadsheet, forward your email, and watch the magic happen."
      />
      <div className="space-y-12">
        <section className="max-w-screen-xl w-full flex flex-col-reverse md:flex-row md:items-center py-8 md:py-14 rounded-lg md:space-x-4">
          <div className="w-full flex justify-center mt-6 md:mt-0">
            <ExplanationCard number={1} title="Upload Your Spreadsheet" body={firstStep} />
          </div>
        </section>
        <section className="max-w-screen-xl w-full flex flex-col-reverse md:flex-row md:items-center py-8 md:py-14 rounded-lg md:space-x-4">
          <div className="w-full flex justify-center mt-6 md:mt-0">
            <ExplanationCard number={2} title="Forward Your Emails" body={secondStep} />
          </div>
        </section>
        <section className="max-w-screen-xl w-full flex flex-col-reverse md:flex-row md:items-center py-8 md:py-14 rounded-lg md:space-x-4">
          <div className="w-full flex justify-center mt-6 md:mt-0">
            <ExplanationCard number={3} title="Get Organized Data Instantly" body={thirdStep} />
          </div>
        </section>
      </div>
    </section>
  );
};

export default SolutionIntro;
